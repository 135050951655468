'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:searchTypeahead
 * @description A custom typeahead component for searching root entities.
 * It allows for searching root entities either in general or by a specific type.
 * This component is designed to overcome limitations of uib-typeahead such as lack of support for transcluded content and scope conditional formatting.
 * @usage `<search-typeahead></search-typeahead>`
 */
angular.module('uasApp').component('searchTypeahead', {
    templateUrl: 'es6/app/forms/typeahead/search.typeahead.html',
    controllerAs: 'searchTypeaheadController',
    controller: function (
        $scope,
        EntityService,
        EntityType,
        Language,
        Pageable,
        Search,
        entityTranslateFilter,
        enumFilter
    ) {
        const searchTypeaheadController = this;

        searchTypeaheadController.$onInit = function () {
            searchTypeaheadController.inputId = _.uniqueId('search-typeahead-');
            searchTypeaheadController.selectedTypeId = `${searchTypeaheadController.inputId}-select-root-type`;
            searchTypeaheadController.dropdownMenuId = `${searchTypeaheadController.inputId}-dropdown-menu`;
            searchTypeaheadController.minLength = 1;
            searchTypeaheadController.waitMs = 300;

            searchTypeaheadController.pageable = Pageable.of({
                pageSize: 20
            });

            searchTypeaheadController.language = Language.get();
            searchTypeaheadController.onLanguage = Language.onChange(() => {
                searchTypeaheadController.language = Language.get();
                setSelectedTypeName();
                setSearchableTypes(searchTypeaheadController.searchableEntityTypes);
                _.map(searchTypeaheadController.groups, translateType);
            });

            loadTypes();
        };

        function loadTypes() {
            searchTypeaheadController.typesIsLoading = true;

            EntityType.searchable()
                .$promise.then((types) => {
                    setSearchableTypes(types);
                })
                .finally(() => {
                    searchTypeaheadController.typesIsLoading = false;
                });
        }

        function setSearchableTypes(types) {
            searchTypeaheadController.searchableEntityTypes = _(types).map(translateType).value();
        }

        function translateType(type) {
            return _.extend(type, {
                name: getLabel(type.entityType)
            });
        }

        function getLabel(entityType) {
            const rootType = EntityService.getRootType(entityType);

            return enumFilter(rootType);
        }

        searchTypeaheadController.typeFilter = function (type) {
            const entityType = EntityService.getEntityType(type);

            return _.some(searchTypeaheadController.searchableEntityTypes, {
                entityType
            });
        };

        searchTypeaheadController.onType = function () {
            setSelectedTypeAsEntityType();
            setSelectedTypeName();
            searchTypeaheadController.search();
        };

        function setSelectedTypeAsEntityType() {
            searchTypeaheadController.selectedTypeAsEntityType = EntityService.getEntityType(
                searchTypeaheadController.selectedType
            );
        }

        function setSelectedTypeName() {
            searchTypeaheadController.selectedTypeName = _.lowerCase(
                enumFilter(searchTypeaheadController.selectedType)
            );
        }

        searchTypeaheadController.search = function (nextPage) {
            delete searchTypeaheadController.selectedItem;

            searchTypeaheadController.termIsValid = isTermValid();
            if (!searchTypeaheadController.termIsValid) {
                delete searchTypeaheadController.totalElements;
                return;
            }

            const params = buildParams(nextPage);

            searchTypeaheadController.loading = true;
            return Search.get(params)
                .$promise.then((page) => {
                    return processSearchResults(page);
                })
                .finally(() => {
                    searchTypeaheadController.loading = false;
                    searchTypeaheadController.dropdownIsOpen = true; // ensure dropdown is open after search
                    delete searchTypeaheadController.focusItemN;
                    setSelected();
                });
        };

        function isTermValid() {
            return (
                !_.isEmpty(searchTypeaheadController.term) &&
                _.size(searchTypeaheadController.term) >= searchTypeaheadController.minLength
            );
        }

        function buildParams(nextPage) {
            if (nextPage) {
                searchTypeaheadController.pageable.currentPage++;
            } else {
                searchTypeaheadController.pageable.currentPage = 1;
            }

            return searchTypeaheadController.pageable.build({
                academicYearId: sessionStorage.academicYear,
                entityType: searchTypeaheadController.selectedTypeAsEntityType,
                search: searchTypeaheadController.term,
                sort: ['sequence,ASC', 'code,ASC'],
                language: searchTypeaheadController.language
            });
        }

        function processSearchResults(page) {
            const items = mapResults(page.content);
            const groups = mapGroups(items);

            searchTypeaheadController.sortedItems = _.flatMap(groups, (group) => group.items);
            searchTypeaheadController.groups = groups;
            searchTypeaheadController.numberOfElements = page.numberOfElements;
            searchTypeaheadController.totalElements = page.totalElements;
            searchTypeaheadController.totalPages = page.totalPages;
            searchTypeaheadController.lastPage = page.last;

            return groups;
        }

        function mapResults(results) {
            return _(results)
                .uniqBy('id')
                .map((result) => {
                    result.type = result.type.trim();
                    result.elementId = `${searchTypeaheadController.inputId}-result-${result.id}`;
                    result.route = EntityService.getUriSref(result);
                    result.displayName = entityTranslateFilter(result);
                    return result;
                })
                .sortBy('displayName')
                .value();
        }

        function mapGroups(items) {
            return _(items)
                .groupBy('type')
                .map((group, typeName) => mapGroup(group, typeName))
                .sortBy('name')
                .value();
        }

        function mapGroup(group, typeName) {
            return {
                id: typeName,
                name: getLabel(typeName),
                items: group,
                open: true
            };
        }

        searchTypeaheadController.clearFiltersAndFocusInput = function () {
            searchTypeaheadController.clearFilters();
            focusInput();
        };

        searchTypeaheadController.onTermChange = function () {
            searchTypeaheadController.search();
        };

        searchTypeaheadController.toggleGroup = function (group) {
            group.open = group.open !== true;
        };

        searchTypeaheadController.loadNextPage = function () {
            searchTypeaheadController.search(true).then(focusInput);
        };

        function focusInput() {
            const elements = $(`#${searchTypeaheadController.inputId}`);

            if (elements && elements.length) {
                elements[0].focus();
            }
        }

        searchTypeaheadController.onToggleDropdown = function (isOpen) {
            searchTypeaheadController.dropdownIsOpen = isOpen;
        };

        searchTypeaheadController.clearFilters = function () {
            delete searchTypeaheadController.selectedItem;
            delete searchTypeaheadController.selectedType;
            setSelectedTypeAsEntityType();
            setSelectedTypeName();
            delete searchTypeaheadController.groups;
            delete searchTypeaheadController.sortedItems;
            delete searchTypeaheadController.term;
            searchTypeaheadController.termIsValid = isTermValid();
            delete searchTypeaheadController.numberOfElements;
            delete searchTypeaheadController.totalElements;
            delete searchTypeaheadController.totalPages;
        };

        searchTypeaheadController.onKeydown = function ($event) {
            if (!searchTypeaheadController.dropdownIsOpen) {
                return;
            }

            // Avoid cursor movement to the beginning of the input on arrow keys
            if ($event.key === 'ArrowUp' || $event.key === 'ArrowDown') {
                $event.preventDefault();
                $event.stopPropagation();
            }

            switch ($event.key) {
                case 'ArrowUp':
                    moveFocusUp();
                    break;
                case 'ArrowDown':
                    moveFocusDown();
                    break;
                case 'Enter':
                    openSelectedOption();
                    break;
            }
        };

        function moveFocusUp() {
            if (angular.isUndefined(searchTypeaheadController.focusItemN)) {
                searchTypeaheadController.focusItemN = 0;
            } else if (searchTypeaheadController.focusItemN > 0) {
                searchTypeaheadController.focusItemN--;
            }
            setSelected();
        }

        function moveFocusDown() {
            if (angular.isUndefined(searchTypeaheadController.focusItemN)) {
                searchTypeaheadController.focusItemN = 1;
            } else if (searchTypeaheadController.focusItemN < searchTypeaheadController.numberOfElements - 1) {
                searchTypeaheadController.focusItemN++;
            }
            setSelected();
        }

        searchTypeaheadController.onMouseEnter = function (item) {
            searchTypeaheadController.selectedItem = item;
            searchTypeaheadController.focusItemN = _.indexOf(searchTypeaheadController.sortedItems, item);
        };

        function setSelected() {
            const { sortedItems, focusItemN } = searchTypeaheadController;
            searchTypeaheadController.selectedItem = _.get(sortedItems, focusItemN, _.first(sortedItems));

            const selectedId = _.get(searchTypeaheadController.selectedItem, 'elementId');
            if (selectedId) {
                const element = $(`#${selectedId}`);
                if (element.length) {
                    element[0].scrollIntoView({ block: 'center' });
                }
            }
        }

        function openSelectedOption() {
            const elementId = _.get(searchTypeaheadController.selectedItem, 'elementId');
            if (elementId) {
                const selectedElement = $(`#${elementId}`);

                if (selectedElement && selectedElement.length) {
                    selectedElement.trigger('click');
                }
            }
        }

        $scope.$on('$stateChangeStart', () => {
            searchTypeaheadController.dropdownIsOpen = false;
            searchTypeaheadController.clearFilters();
        });
    }
});
