'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:Clipboard
 * @description
 * The Clipboard factory. Opens a modal with a textarea to copy text to the clipboard.
 * @deprecated Use ClipboardModal.tsx instead.
 */
angular.module('uasApp')
  .factory('Clipboard', function($uibModal) {
    return {
      copy: function({ value, styles }) {
        $uibModal.open({
          templateUrl: 'views/modals/clipboard.html',
          windowClass: 'clipboard',
          controllerAs: 'clipboardController',
          controller: function() {
            const clipboardController = this;

            clipboardController.$onInit = function () {
              clipboardController.styles = styles;
              clipboardController.value = angular.copy(value);
            };
          }
        });
      }
    };
  });
