'use strict';

angular.module('uasApp').component('pageList', {
    bindings: {
        parameters: '<'
    },
    templateUrl: 'es6/page/page.list.html',
    controllerAs: 'pageListController',
    controller: function($state, Pageable, Page, Message, Language, PAGES, i18nFilter) {
        const pageListController = this;

        pageListController.$onInit = function() {
            pageListController.pageable = Pageable.parse(pageListController.parameters);

            pageListController.route = pageListController.parameters.route;
            pageListController.name = pageListController.parameters.name;
            pageListController.rootType = pageListController.parameters.rootType;

            loadData();
            pageListController.onLanguage = Language.onChange(() => loadData());
        };

        pageListController.$onDestroy = function() {
            pageListController.onLanguage();
        };

        function loadData() {
            pageListController.loading = true;
            Page.query().$promise.then((pages) => {
                pageListController.pages = _.map(pages, (page) => {
                    page.title = i18nFilter(page.titles);
                    page.valid = page.route && _.some(PAGES, { name: page.route });
                    return page;
                });

                const routes = _.groupBy(pages, 'route');
                pageListController.usedRoutes = _.transform(routes,  (result, value, key) => {
                    result[key] = value.length;
                }, {});

                search();
            }).finally(() => {
                pageListController.loading = false;
            });
        }

        function search() {
            pageListController.filtered = _.filter(pageListController.pages, (page) => {
                return hasRoute(page) && hasName(page) && hasType(page);
            });
        }

        function hasRoute(page) {
            return !pageListController.route || page.route === pageListController.route;
        }

        function hasName(page) {
            if (_.isEmpty(pageListController.name)) {
                return true;
            }

            return matches(page.name, pageListController.name) || matches(page.title, pageListController.name);
        }

        function matches(value, searchTerm) {
            return _.includes(_.lowerCase(value), _.lowerCase(searchTerm));
        }

        function hasType(page) {
            return !pageListController.rootType || page.rootType === pageListController.rootType;
        }

        pageListController.search = function() {    
            search();
            setParameters();        
        };

        function setParameters() {
            const parameters = pageListController.pageable.build();
            parameters.route = pageListController.route;
            parameters.name = pageListController.name;
            parameters.rootType = pageListController.rootType;

            $state.go('pages', parameters, { notify: false });
        }

        pageListController.onPage = function() {
            setParameters();
        };

        pageListController.create = function(type) {
            $state.go('page-create', {
                type: type
            });
        };

        pageListController.save = function(page) {
            Page.save(page).$promise.then((result) => {
                page.id = result.id;
            });
        };

        pageListController.delete = function(page) {
            Page.delete({ id: page.id }).$promise.then(() => {
                _.remove(pageListController.pages, { id: page.id });
                Message.addSuccessLabel('Static.Message.DataRemoved');
                pageListController.search();
            });
        };
    }
});
