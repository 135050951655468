'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Session
 * @description
 * The Session service.
 */
angular.module('uasApp').factory('Session', function () {
    const listeners = [];

    const isBoolean = (value) => value === 'false' || value === 'true';
    const isUndefinedOrNull = (value) => value === 'undefined' || value === 'null';

    const getFromSessionStorage = (item) => {
        const sessionItem = sessionStorage.getItem(item);
        if (isUndefinedOrNull(sessionItem)) {
            return null;
        }
        if (isBoolean(sessionItem)) {
            return sessionItem !== 'false';
        }
        return sessionItem;
    };

    const prefix = 'authentication';
    const sessionItems = [
        'personId',
        'name',
        'externalId',
        'role',
        'authenticated',
        'authorized',
        'restorable',
        'provider',
        'simulation',
        'photoUrl'
    ];

    sessionItems.forEach((sessionItem) => {
        const value = getFromSessionStorage(`${prefix}.${sessionItem}`);
        if (!value) {
            return;
        }
        this[sessionItem] = value;
    });

    this.create = function (authentication) {
        sessionItems.forEach((sessionItem) => {
            sessionStorage.setItem(`${prefix}.${sessionItem}`, authentication[sessionItem]);
        });

        this.personId = authentication.personId;
        this.name = authentication.name;
        this.externalId = authentication.externalId;
        this.role = authentication.role;
        this.authenticated = authentication.authenticated;
        this.authorized = authentication.authorized;
        this.restorable = authentication.restorable;
        this.provider = authentication.provider;
        this.simulation = authentication.simulation;
        this.photoUrl = authentication.photoUrl;

        notifyAll();
    };

    function notifyAll() {
        _.each(listeners, function (listener) {
            listener(this);
        });
    }

    this.destroy = function () {
        delete sessionStorage.token;

        sessionItems.forEach((sessionItem) => {
            sessionStorage.removeItem(`${prefix}.${sessionItem}`);
        });

        this.personId = undefined;
        this.name = undefined;
        this.externalId = undefined;
        this.role = undefined;
        this.provider = undefined;
        this.photoUrl = undefined;
        this.authenticated = false;
        this.authorized = false;
        this.restorable = false;
        this.simulation = false;

        notifyAll();
    };

    this.onChange = function (listener) {
        listeners.push(listener);
    };

    return this;
});
