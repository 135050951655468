'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:State
 * @description
 * Factory to manage state transitions.
 */
angular.module('uasApp').factory('State', function ($window, $state) {
    function reload(name, parameters) {
        if (name) {
            const baseUrl = $window.location.href.split('#')[0];
            const url = $state.href(name, parameters);
            $window.location.href = baseUrl + url;
        }

        $window.location.reload();
    }

    return { reload };
});
