'use strict';

angular.module('uasApp').component('stateSelect', {
  bindings: {
    selectId: '@'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/navigation/state.select.html',
  controllerAs: 'stateSelectController',
  controller: function ($state) {
    const stateSelectController = this;

    stateSelectController.$onInit = function () {
      stateSelectController.states = getStates();
      stateSelectController.ngModelCtrl.$render = setStateId;
    };

    function getStates() {
      const states = $state.get();
      return _(states)
        .map((state, $index) => ({
          id: $index,
          name: state.name
        }))
        .filter((state) => !!state.name)
        .sortBy('name')
        .value();
    }

    function setStateId() {
      const value = stateSelectController.ngModelCtrl.$modelValue;
      if (angular.isDefined(value)) {
        const state = _.find(stateSelectController.states, { name: value });
        stateSelectController.stateId = _.get(state, 'id');
      } else {
        delete stateSelectController.stateId;
      }
    }

    stateSelectController.onSelect = function () {
      const state = _.find(stateSelectController.states, { id: stateSelectController.stateId });
      stateSelectController.ngModelCtrl.$setViewValue(_.get(state, 'name'));
    };
  }
});
