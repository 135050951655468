angular.module('uasApp').component('uasHeader', {
    templateUrl: 'es6/app/header/header.html',
    controller: function (
        $scope,
        Session,
        Debug,
        Process,
        CustomPage,
        AuthService,
        SecurityService,
        Simulation,
        AcademicYearHolder,
        Message,
        translateFilter,
        Parameter,
        Period,
        TEOrganization,
        enumFilter,
        Language
    ) {
        $scope.Message = Message;
        $scope.isCollapsed = true;
        $scope.isAdmin = AuthService.isAdmin;
        $scope.Debug = Debug;
        Parameter.load().then(() => {
            const showSwitcher = Parameter.getParameterAsBoolean('show.switcher', false);
            if (showSwitcher) {
                $scope.showSwitcher = true;

                TEOrganization.get().$promise.then((teOrganization) => {
                    if (_.isEmpty(teOrganization) || _.isEmpty(teOrganization.enabledProducts)) {
                        $scope.showSwitcher = false;
                        return;
                    }

                    $scope.teOrganization = teOrganization;
                    $scope.teUser = {
                        // mocked user with all products and scopes from organization enabled for demo purposes
                        appPermissions: $scope.teOrganization.enabledProducts,
                        scopes: $scope.teOrganization.enabledProducts
                    };
                });
            }
        });

        CustomPage.query().$promise.then((pages) => {
            $scope.pages = pages;
        });

        $scope.isAllowed = function (requested) {
            if (AuthService.isAdmin()) {
                return true;
            }
            return SecurityService.isAllowed(requested, $scope.operations);
        };

        $scope.reportOperations = {
            processManagement: ['VIEW_PROCESS_MANAGEMENT'],
            processProgress: ['VIEW_PROCESS_PROGRESS'],
            schedulePreference: ['VIEW_SCHEDULE_PREFERENCE_REPORT'],
            scheduleActivities: ['VIEW_SCHEDULE', 'VIEW_SCHEDULE_ACTIVITIES'],
            changeReport: ['VIEW_CHANGE_REPORT_TEMPLATES']
        };

        $scope.showReportMenu = function () {
            return $scope.isAllowed(_.flatten(_.values($scope.reportOperations)), $scope.operations);
        };

        $scope.hasInventory = () =>
            SecurityService.isAllowed(['VIEW_RESOURCES', 'EDIT_RESOURCES'], $scope.personOperations);

        $scope.$on('$stateChangeSuccess', function (evt) {
            evt.preventDefault();
            $scope.isCollapsed = true;
        });

        AuthService.addAuthenticationListener(() => {
            // Set person id on scope so that we can watch changes
            $scope.personId = Session.personId;
        });

        $scope.Session = Session;
        $scope.AcademicYearHolder = AcademicYearHolder;

        $scope.status = {
            isopen: false
        };

        $scope.restore = () => {
            AuthService.restore();
        };

        $scope.toggleDropdown = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.status.isopen = !$scope.status.isopen;
        };

        $scope.logout = AuthService.logout;

        // Academic years

        if (AcademicYearHolder.hasLoaded()) {
            loadYearData();
        }

        $scope.$on('SessionStorage.AcademicYear.Set', loadYearData);

        function loadYearData() {
            // Set academic year id on scope so that we can watch changes
            $scope.academicYearId = sessionStorage.academicYear;

            Process.query({
                academicYearId: sessionStorage.academicYear
            }).$promise.then((processes) => {
                const grouped = _(processes)
                    .sortBy(['type.sequence', 'period.start', 'period.end', 'startDate', 'endDate', 'type.name'])
                    .uniqBy('type.id')
                    .groupBy('type.rootType')
                    .value();

                $scope.singleProcesses = _(grouped)
                    .filter((value) => value.length === 1)
                    .flatten()
                    .value();

                $scope.processes = _.omitBy(grouped, (value) => value.length === 1);
                $scope.rootTypes = _.keys($scope.processes).map((type) => ({
                    type
                }));

                setDisplayName();
            });

            function setDisplayName() {
                _.forEach($scope.rootTypes, (rootType) => {
                    rootType.displayName = enumFilter(rootType.type);
                });
            }
            Language.onChange(setDisplayName);

            Period.query({
                academicYearId: sessionStorage.academicYear
            }).$promise.then((periods) => {
                $scope.period = _(periods).filter({ planning: true }).sortBy('start').head();
            });

            return Simulation.mine().$promise.then((result) => {
                let years = [];
                years = years.concat(result.years);
                years = years.concat(years);

                _.each(years, (year) => {
                    year.original = _.find(years, { id: year.originId });
                });

                $scope.years = _.filter(result.years, {
                    enabled: true,
                    simulation: false
                });
                $scope.simulations = _.filter(result.years, {
                    enabled: true,
                    simulation: true
                });
                $scope.allYears = result.years;
                $scope.simulate = result.create === true;
            });
        }

        $scope.$watch('academicYearId', (newAcademicYearId, oldAcademicYearId) => {
            if (
                newAcademicYearId !== oldAcademicYearId &&
                AcademicYearHolder.hasLoaded() &&
                AuthService.isAuthenticated()
            ) {
                delete $scope.operations;
                AuthService.operations('academic-year', sessionStorage.academicYear).then((operations) => {
                    $scope.operations = operations;
                });
            }
        });

        $scope.$watch('Session.personId', (newPersonId, oldPersonId) => {
            if (newPersonId !== oldPersonId || !$scope.personOperations) {
                delete $scope.personOperations;
                AuthService.operations('person', Session.personId).then((operations) => {
                    $scope.personOperations = operations;
                });
            }
        });

        $scope.switchAcademicYear = function (academicYear) {
            if (!AcademicYearHolder.isCurrent(academicYear)) {
                AcademicYearHolder.transition(academicYear);
                return Message.addSuccess(
                    translateFilter('Static.Message.SwitchAcademicYear', {
                        academicyear: academicYear.code
                    })
                );
            }
        };
    }
});
