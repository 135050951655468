import timeeditLogo from '../../../images/timeedit.svg';

angular.module('uasApp').component('uasFooter', {
    templateUrl: 'es6/app/footer/footer.html',
    controller: function ($scope, $rootScope, Session, translateFilter, Language) {
        $scope.timeeditLogoUrl = timeeditLogo;
        $scope.APP = $rootScope.APP;
        $scope.systemInfo = $rootScope.systemInfo;
        $scope.criticalHealth = $rootScope.criticalHealth;
        $scope.clientInfo = $rootScope.clientInfo;
        $scope.environment = $rootScope.environment;
        $scope.Session = Session;

        const FAQ_URL_LABEL = 'Static.Label.Faq.Url';
        const MANUAL_URL_LABEL = 'Static.Label.Manual.Url';

        function setFaqAndManual() {
            $scope.faqUrl = translateFilter(FAQ_URL_LABEL);
            $scope.faqAvailable = $scope.faqUrl !== FAQ_URL_LABEL && urlIsAvailable($scope.faqUrl);
            $scope.manualUrl = translateFilter(MANUAL_URL_LABEL);
            $scope.manualAvailable = $scope.manualUrl !== MANUAL_URL_LABEL && urlIsAvailable($scope.manualUrl);
            $scope.statusLabel = getStatusLabel();
        }

        function urlIsAvailable(url) {
            return url !== '' && url !== '#';
        }

        function getStatusLabel() {
            const status = _.get($scope.criticalHealth, 'status', 'UNKNOWN');
            return `Static.Footer.About.Api.Status.${status}`;
        }

        setFaqAndManual();

        Language.onChange(setFaqAndManual);
    }
});
