// eslint-disable-next-line no-shadow
enum DesignModes {
    On = 'on',
    Off = 'off'
}

export class DesignMode {
    public static toggle(event: KeyboardEvent): void {
        if (document.designMode === 'on') {
            this.setDesignMode(DesignModes.Off);
            return;
        }

        const activeElement = document.activeElement as HTMLElement;

        if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
            (activeElement as HTMLInputElement | HTMLTextAreaElement).value += event.key; // Make sure the last typed key is added to the input
            event.preventDefault(); // Prevent the key from also being added to the top of the document
        }

        this.setDesignMode(DesignModes.On);
    }

    private static setDesignMode(mode: DesignModes): void {
        document.designMode = mode;
    }
}
