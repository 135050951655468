'use strict';

angular.module('uasApp').factory('objectLinkInterceptor', function () {
  return {
    request: function (request) {
      // Add the origin of the current object to the request. Used to create links to objects.

      // Remove the domain URL and the Angular prefix.
      const origin = window.nextjs ? window.location.pathname : window.location.hash;

      request.headers['Object-origin'] = origin;
      return request;
    }
  };
});
