'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasRequestLog
 * @description
 * Displays request logs.
 */
angular.module('uasApp')
    .component('requestLog', {
        templateUrl: 'es6/admin/request-log/request.log.html',
        controllerAs: 'requestLogController',
        controller: function($scope, $injector, EntityService, Pageable, RequestLog) {
            const requestLogController = this;

            requestLogController.$onInit = function() {
                requestLogController.pageable = Pageable.of({
                    order: 'responseDate',
                    reverse: true
                });
                requestLogController.injector = $injector;

                requestLogController.search();
            };

            requestLogController.search = function() {
                let params = requestLogController.pageable.build(requestLogController.parameters);
                if (angular.isDefined(requestLogController.entity)) {
                    params.entityType = EntityService.getType(requestLogController.entity);
                    params.entityId = requestLogController.entity.id;
                }
                
                requestLogController.loading = true;
                RequestLog.get(params).$promise.then((results) => {
                    requestLogController.requestLogsResults = results;
                }).finally(() => {
                    requestLogController.loading = false;
                });
            };

            requestLogController.onEntity = function(entity) {
                requestLogController.entity = entity;
            };

            requestLogController.openModal = function(id) {
                RequestLog.get({ id }).$promise.then((requestLog) => {
                    requestLogController.requestLog = requestLog;
                });
            };
        }
    });