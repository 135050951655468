/**
 * Adds unicorns and rainbows to the page.
 * @see https://www.cornify.com/
 * @see https://github.com/daphnesmit/use-cornify
 */

export class Cornify {
    private static instance: Cornify | null = null; // Singleton instance
    private count: number = 0;
    private readonly cornifyUrl = 'https://www.cornify.com/';

    constructor() {
        // Retrieve our click count from the cookie when we start up.
        this.initCounter();
    }

    // Static method to get/create the singleton instance and start the process
    public static start() {
        if (!this.instance) {
            this.instance = new Cornify();
        }
        this.instance.add();
    }

    private initCounter() {
        this.count = parseInt(this.getCookie('cornify'), 10);
        if (isNaN(this.count)) {
            this.count = 0;
        }
    }

    public add() {
        this.count += 1;
        const showGrandUnicorn = this.count === 15;
        let transform = 'translate(-50%, -50%)';

        const container = this.createUnicornContainer();

        if (showGrandUnicorn) {
            this.showGrandUnicorn(container);
        } else {
            this.randomizeUnicornPosition(container);
            transform += ` rotate(${Math.round(Math.random() * 10 - 5)}deg)`;
        }

        const img = this.createUnicornImage();
        const currentTime = new Date();
        let submitTime = currentTime.getTime();

        if (showGrandUnicorn) {
            submitTime = 0;
        }

        const url = `${this.cornifyUrl}getacorn.php?r=${submitTime}&url=${document?.location.href}`;

        container.style.transform = transform;
        img.setAttribute('src', url);
        container.onmouseover = () => this.onUnicornMouseOver(img);
        container.onmouseout = () => this.onUnicornMouseOut(img);

        document.body.appendChild(container);
        container.appendChild(img);

        if (this.count > 5) {
            this.addCornifyStyles();
        }

        this.updateCount();
    }

    private createUnicornContainer() {
        const div = document.createElement('div');
        div.style.position = 'fixed';
        div.className = '__cornify_unicorn';
        div.style.zIndex = '143143';
        div.style.outline = '0';
        div.onclick = () => this.add(); // Click for more magic.
        return div;
    }

    private showGrandUnicorn(container: HTMLDivElement) {
        container.style.top = '50%';
        container.style.left = '50%';
        container.style.zIndex = '143143143';
    }

    private randomizeUnicornPosition(container: HTMLDivElement) {
        container.style.top = `${Math.round(Math.random() * 100)}%`;
        container.style.left = `${Math.round(Math.random() * 100)}%`;
    }

    private createUnicornImage() {
        const img = document.createElement('img');

        img.style.opacity = '0';
        img.style.transition = 'all .1s linear'; // Add a nice hover wiggle.
        img.style.cursor = 'pointer';
        img.alt = 'A lovely unicorn or rainbow';
        img.onload = () => {
            img.style.opacity = '1';
        };

        return img;
    }

    private onUnicornMouseOver(img: HTMLImageElement) {
        const size = 1 + Math.round(Math.random() * 10) / 100;
        const angle = Math.round(Math.random() * 20 - 10);

        img.style.transform = `rotate(${angle}deg) scale(${size},${size})`;
    }

    private onUnicornMouseOut(img: HTMLImageElement) {
        const size = 0.9 + Math.round(Math.random() * 10) / 100;
        const angle = Math.round(Math.random() * 6 - 3);

        img.style.transform = `rotate(${angle}deg) scale(${size},${size})`;
    }

    private addCornifyStyles() {
        const cssExisting = document.getElementById('__cornify_css');

        if (!cssExisting) {
            const head = document.head || document.getElementsByTagName('head')[0];
            const style = document.createElement('style');

            style.id = '__cornify_css';
            style.type = 'text/css';
            style.innerHTML = `
        *:not(i) {
          color: #FF00FF !important;
          font-family: "Comic Sans MS", "Marker Felt" !important;
        }
      `;

            head.appendChild(style);
        }
    }

    private updateCount() {
        const id = '__cornify_count';

        let cornify_count_element = document.getElementById(id);
        if (!cornify_count_element) {
            cornify_count_element = this.createCountElement(id);
            document.body.appendChild(cornify_count_element);
        }
        cornify_count_element.innerHTML = `${this.count} ${this.count === 1 ? 'UNICORN OR RAINBOW CREATED!' : 'UNICORNS &AMP; RAINBOWS CREATED'}`;

        this.setCookie('cornify', `${this.count}`, 7);
    }

    private createCountElement(id: string) {
        const countElement = document.createElement('p');

        countElement.id = id;
        countElement.style.position = 'fixed';
        countElement.style.top = '0px';
        countElement.style.left = '0px';
        countElement.style.right = '0px';
        countElement.style.zIndex = '1000000000';
        countElement.style.color = '#ff00ff';
        countElement.style.textAlign = 'center';
        countElement.style.fontSize = '24px';

        return countElement;
    }

    private setCookie(name: string, value: string, days: number) {
        const date = new Date();
        // This correctly adds the specified number of days to the date
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + date.toUTCString();
        const sameSite = 'SameSite=Strict';
        document.cookie = `${name}=${value}; ${expires}; ${sameSite}; path=/`;
    }

    private getCookie(cname: string) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (const element of ca) {
            const c = element.trim();
            if (c.startsWith(name)) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
