'use strict';

angular.module('uasApp').component('routeSelect', {
  bindings: {
    selectId: '@',
    usedRoutes: '<?',
    isRequired: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/page/route.select.html',
  controllerAs: 'routeSelectController',
  controller: function (PAGES) {
    const routeSelectController = this;

    routeSelectController.$onInit = function () {
      routeSelectController.routes = getRoutes();
      routeSelectController.ngModelCtrl.$render = setRouteId;
    };

    function getRoutes() {
      return _(PAGES)
        .map((route, $index) => ({
          id: $index,
          originalName: route.name,
          label: getLabel(route.name)
        }))
        .sortBy('label')
        .value();
    }

    function getLabel(name) {
      if (angular.isDefined(routeSelectController.usedRoutes)) {
        const used = _.get(routeSelectController.usedRoutes, name, 0);
        return `${name} (${used})`;
      }
      return name;
    }

    routeSelectController.$onChanges = function () {
      routeSelectController.routes = getRoutes();
    };

    function setRouteId() {
      const value = routeSelectController.ngModelCtrl.$modelValue;
      if (angular.isDefined(value)) {
        const route = _.find(routeSelectController.routes, { originalName: value });
        routeSelectController.routeId = _.get(route, 'id');
      } else {
        delete routeSelectController.routeId;
      }
    }

    routeSelectController.onChange = function () {
      const route = _.find(routeSelectController.routes, { id: routeSelectController.routeId });
      routeSelectController.ngModelCtrl.$setViewValue(_.get(route, 'originalName'));
    };
  }
});
