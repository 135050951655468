'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasLogin
 * @description
 * # uasLogin
 * Login page of the uasApp
 */
angular.module('uasApp').component('uasLogin', {
    bindings: {
        redirect: '<?',
        successUrl: '<?'
    },
    templateUrl: 'es6/security/login.html',
    controllerAs: 'loginController',
    controller: function (AuthService, AuthenticateToken, $timeout, $window, Message, AcademicYearHolder, Parameter) {
        const loginController = this;

        loginController.$onInit = function () {
            angular.element(document).bind('keydown', keydownListener);

            setSuccessUrl();

            loginController.showLoginForm = loginController.redirect === false;
            if (loginController.redirect === false) {
                loadConfig();
            } else {
                loadData();
            }
        };

        loginController.$onDestroy = function () {
            angular.element(document).unbind('keydown', keydownListener);
        };

        function setSuccessUrl() {
            if (loginController.successUrl) {
                sessionStorage.successUrl = getUrl(loginController.successUrl);
            }
        }

        function getUrl(location) {
            if (window.nextjs) {
                if (_.startsWith(location, '/')) {
                    return location;
                }

                return '/dashboard';
            }

            if (_.startsWith(location, '/#/')) {
                return location;
            } else if (_.startsWith(location, '/')) {
                return '/#' + location;
            } else {
                return '/#/dashboard';
            }
        }

        function loadData() {
            loginController.loading = true;
            AuthService.current().then(
                (result) => {
                    AcademicYearHolder.load().then(() => {
                        goToSuccessUrl(result);
                    });
                },
                () => {
                    loginController.loading = true;
                    getConfig()
                        .then((config) => {
                            const defaultLoginUrl = config.defaultLoginUrl;
                            if (_.isEmpty(defaultLoginUrl)) {
                                // not logged in and no redirect, show login form
                                return setLoginButtons(config);
                            } else if (_.startsWith(defaultLoginUrl, window.nextjs ? '/token' : '/#/token')) {
                                // not logged in, redirect to our authentication service
                                return AuthenticateToken.authenticate(config);
                            } else {
                                // not logged in, redirect to external login page
                                $timeout(() => goToLogin(defaultLoginUrl), 1000);
                            }
                        })
                        .finally(() => {
                            loginController.loading = false;
                        });
                }
            );
        }

        function getConfig() {
            return AuthService.getConfig().then((config) => {
                _.forEach(config.registrations, (registration) => {
                    const { id, label } = registration;
                    registration.name = label || _.capitalize(id).replace('-', ' ').replace('_', ' ');
                    registration.type = getType(id);
                });
                return config;
            });
        }

        function getType(id) {
            let type = '';
            if (id.indexOf('google') !== -1) {
                type = 'google';
            } else if (id === 'surfconext') {
                type = 'surfconext';
            } else if (id.startsWith('te-')) {
                type = 'timeedit';
            }
            return type;
        }

        function setLoginButtons(config) {
            loginController.registrations = _.sortBy(config.registrations, 'name');
            loginController.showLoginForm = true;
            return Parameter.load().then(() => {
                loginController.showLoginButton = Parameter.getParameterAsBoolean('user.login', true);
            });
        }

        function loadConfig() {
            loginController.loading = true;
            getConfig()
                .then((config) => {
                    return setLoginButtons(config);
                })
                .finally(() => {
                    loginController.loading = false;
                });
        }

        function goToLogin(loginUrl) {
            loginController.loading = false;
            $window.location.href = loginUrl;
        }

        loginController.login = function (username, password) {
            AuthService.login(username, password).then(
                function (result) {
                    if (angular.isUndefined(AcademicYearHolder.current)) {
                        AcademicYearHolder.load().then(() => {
                            goToSuccessUrl(result);
                        });
                        return;
                    }
                    goToSuccessUrl(result);
                },
                function () {
                    Message.addError('Invalid username/password.');
                }
            );
        };

        function goToSuccessUrl(result) {
            if (result.authenticated === true) {
                $window.location.href = getUrl(sessionStorage.successUrl);
                delete sessionStorage.successUrl;
            }
        }

        function keydownListener(e) {
            // When pressing escape (27)
            if (e.keyCode === 27) {
                loadConfig();
            }
        }
    }
});
